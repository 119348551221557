import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { graphql, Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './characters-stats.scss';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import lodash from 'lodash';
import { IHSRCharacter } from '../../../modules/common/model/graphql-types';
import {
  faAsterisk,
  faSortAlphaUp,
  faSortAmountUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterBar } from '../../../modules/cs/common/components/filter-bar/filter-bar';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import Select from 'react-select';
import Switch from 'react-switch';
import { HSRCalculateStats } from '../../../modules/hsr/utils/hsr-stat-calc';

interface IHSRCharacterNodes {
  nodes: IHSRCharacter[];
}

interface IHSRCharacterEntry {
  allCharacters: IHSRCharacterNodes;
}

interface IProps {
  data: IHSRCharacterEntry;
}

const HSRCharactersStatsPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const FILTER_STORAGE_KEY = 'hsr-charactersstats-filter-stats';
  const OPTS_STORAGE_KEY = 'hsr-charactersstats-opts-stats';

  const isBrowser = typeof window !== 'undefined';
  let currentOpts = isBrowser
    ? JSON.parse(window.sessionStorage.getItem(OPTS_STORAGE_KEY))
    : {};
  if (!currentOpts) {
    currentOpts = {};
  }

  const [activeFilters, setActiveFilters] = useState(
    isBrowser
      ? JSON.parse(window.sessionStorage.getItem(FILTER_STORAGE_KEY))
      : {} || {}
  );
  const [sortOption, setSortOption2] = useState(currentOpts.sort || null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [level, setLevel] = useState(80);
  const [enableAscension, setEnableAscension] = useState(true);

  const levelOption = [
    { value: 1, label: 'Level 1' },
    { value: 20, label: 'Level 20' },
    { value: 30, label: 'Level 30' },
    { value: 40, label: 'Level 40' },
    { value: 50, label: 'Level 50' },
    { value: 60, label: 'Level 60' },
    { value: 70, label: 'Level 70' },
    { value: 80, label: 'Level 80' }
  ];

  let defaultLevel = level
    ? levelOption.find((opt) => opt.value === level)
    : null;

  if (defaultLevel === null) {
    defaultLevel = levelOption[7];
    setLevel(defaultLevel.value);
  }

  const levelChangeHandler = (e) => {
    if (e.value === 1) {
      setEnableAscension(false);
    }
    if (e.value === 80) {
      setEnableAscension(true);
    }
    setLevel(e.value);
  };

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '4★', value: '4' },
          { label: '5★', value: '5' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/ele_physical.png"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/ele_fire.png"
                width={24}
                alt="Fire"
              />
            )
          },
          {
            value: 'Ice',
            tooltip: 'Ice',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/ele_ice.png"
                width={24}
                alt="Ice"
              />
            )
          },
          {
            value: 'Lightning',
            tooltip: 'Lightning',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/ele_lightning.png"
                width={24}
                alt="Lightning"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/ele_wind.png"
                width={24}
                alt="Wind"
              />
            )
          },
          {
            value: 'Quantum',
            tooltip: 'Quantum',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/ele_quantum.png"
                width={24}
                alt="Quantum"
              />
            )
          },
          {
            value: 'Imaginary',
            tooltip: 'Imaginary',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/ele_imaginary.png"
                width={24}
                alt="Imaginary"
              />
            )
          }
        ]
      },
      {
        key: 'path',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Abundance',
            tooltip: 'Abundance',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/path_abundance.png"
                width={24}
                alt="Abundance"
              />
            )
          },
          {
            value: 'Destruction',
            tooltip: 'Destruction',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/path_destruction.png"
                width={24}
                alt="Destruction"
              />
            )
          },
          {
            value: 'Erudition',
            tooltip: 'Erudition',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/path_erudition.png"
                width={24}
                alt="Erudition"
              />
            )
          },
          {
            value: 'Harmony',
            tooltip: 'Harmony',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/path_harmony.png"
                width={24}
                alt="Harmony"
              />
            )
          },
          {
            value: 'Hunt',
            tooltip: 'Hunt',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/path_hunt.png"
                width={24}
                alt="Hunt"
              />
            )
          },
          {
            value: 'Nihility',
            tooltip: 'Nihility',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/path_nihility.png"
                width={24}
                alt="Nihility"
              />
            )
          },
          {
            value: 'Preservation',
            tooltip: 'Preservation',
            image: (
              <StaticImage
                src="../../../images/starrail/icons/path_preservation.png"
                width={24}
                alt="Preservation"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: isBrowser
      ? JSON.parse(window.sessionStorage.getItem(FILTER_STORAGE_KEY)) || {}
      : {}
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
      if (activeFilters.path && activeFilters.path !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.path.toLowerCase() === activeFilters.path.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.slug > b.slug ? 1 : -1));
    } else if (sortOption === 'stats_hp') {
      filtered = filtered.sort((a, b) =>
        a.stats.hp_base * 1 < b.stats.hp_base * 1 ? 1 : -1
      );
    } else if (sortOption === 'stats_atk') {
      filtered = filtered.sort((a, b) =>
        a.stats.atk_base * 1 < b.stats.atk_base * 1 ? 1 : -1
      );
    } else if (sortOption === 'stats_def') {
      filtered = filtered.sort((a, b) =>
        a.stats.def_base * 1 < b.stats.def_base * 1 ? 1 : -1
      );
    } else if (sortOption === 'stats_spd') {
      filtered = filtered.sort((a, b) =>
        a.stats.speed_base * 1 < b.stats.speed_base * 1 ? 1 : -1
      );
    } else if (sortOption === 'energy_cost') {
      filtered = filtered.sort((a, b) =>
        a.energyUltimate * 1 < b.energyUltimate * 1 ? 1 : -1
      );
    }

    if (isBrowser) {
      window.sessionStorage.setItem(
        FILTER_STORAGE_KEY,
        JSON.stringify(activeFilters || {})
      );
      const newOpts = {
        sort: sortOption
      };
      window.sessionStorage.setItem(
        OPTS_STORAGE_KEY,
        JSON.stringify(newOpts || {})
      );
    }
    setVisibleCharacters(filtered);
    forceUpdate();
  }, [activeFilters, data.allCharacters.nodes, sortOption, isBrowser]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: 'stats_hp', label: 'Sort by HP' },
    { value: 'stats_atk', label: 'Sort by ATK' },
    { value: 'stats_def', label: 'Sort by DEF' },
    { value: 'stats_spd', label: 'Sort by SPD' },
    { value: 'stats_spd', label: 'Sort by Energy Cost' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption2(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke character-stats-ag hsr'}
      game="hsr"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters stats</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_characters.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Characters stats</h1>
          <h2>A quick way to compare characters stats in Honkai: Star Rail.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Characters"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} character
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar hsr">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <Accordion defaultActiveKey="0" className="stats-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Customize stats</Accordion.Header>
          <Accordion.Body>
            Use the controls below to customize the stats.
            <div className="stats-controls">
              <Select
                options={levelOption}
                className="custom-dropdown"
                isSearchable={false}
                onChange={levelChangeHandler}
                defaultValue={defaultLevel}
                classNamePrefix="custom-dropdown-inner"
              />
              <div className="custom-switch">
                <Switch
                  checked={enableAscension}
                  onChange={() => setEnableAscension(!enableAscension)}
                  disabled={level === 1 || level === 80}
                  onColor="#009EEC"
                  offColor="#484950"
                  className="switch"
                />
                Enable Ascension
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Row>
        <Col>
          <Table className="stats-table" striped bordered responsive>
            <thead>
              <tr>
                <th
                  scope="col"
                  className="name"
                  onClick={() => setSortOption2('+fullName')}
                >
                  Name
                  {sortOption && sortOption === '+fullName' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption2('stats_hp')}
                >
                  <StaticImage
                    src="../../../images/starrail/icons/stat_hp.png"
                    alt="HP"
                  />
                  <span>HP</span>
                  {sortOption && sortOption === 'stats_hp' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption2('stats_atk')}
                >
                  <StaticImage
                    src="../../../images/starrail/icons/stat_atk.png"
                    alt="ATK"
                  />
                  <span>ATK</span>
                  {sortOption && sortOption === 'stats_atk' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption2('stats_def')}
                >
                  <StaticImage
                    src="../../../images/starrail/icons/stat_def.png"
                    alt="DEF"
                  />
                  <span>DEF</span>
                  {sortOption && sortOption === 'stats_def' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption2('stats_spd')}
                >
                  <StaticImage
                    src="../../../images/starrail/icons/stat_speed.png"
                    alt="SPD"
                  />
                  <span>Speed</span>
                  {sortOption && sortOption === 'stats_spd' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption2('energy_cost')}
                >
                  <span>Energy Cost</span>
                  {sortOption && sortOption === 'energy_cost' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {visibleCharacters.map((emp, index) => (
                <tr key={index}>
                  <th scope="row" className="char">
                    <HSRCharacter slug={emp.slug} mode="icon" enablePopover />
                    <span className="name">{emp.name}</span>
                  </th>
                  <td className="stat">
                    {emp.stats.hp_base && (
                      <HSRCalculateStats
                        base={emp.stats.hp_base}
                        level={level}
                        enableAscension={enableAscension}
                      />
                    )}
                  </td>
                  <td className="stat">
                    {emp.stats.atk_base && (
                      <HSRCalculateStats
                        base={emp.stats.atk_base}
                        level={level}
                        enableAscension={enableAscension}
                      />
                    )}
                  </td>
                  <td className="stat">
                    {emp.stats.def_base && (
                      <HSRCalculateStats
                        base={emp.stats.def_base}
                        level={level}
                        enableAscension={enableAscension}
                      />
                    )}
                  </td>
                  <td className="stat">
                    {emp.stats.speed_base && emp.stats.speed_base}
                  </td>
                  <td className="stat">
                    {emp.energyUltimate && emp.energyUltimate}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../../images/starrail/no_results.png"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default HSRCharactersStatsPage;

export const Head: React.FC = () => (
  <Seo
    title="Characters stats | Honkai: Star Rail | Prydwen Institute"
    description="A quick way to compare characters stats in Honkai: Star Rail."
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulHsrCharacter(
      filter: { availableInCbt3: { ne: true } }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        element
        path
        energyUltimate
        stats {
          hp_base
          def_base
          atk_base
          speed_base
        }
      }
    }
  }
`;
