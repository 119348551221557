import React from 'react';

interface IProps {
  base: number;
  level: number;
  enableAscension?: boolean;
}

export const HSRCalculateStats: React.FC<IProps> = ({
  base,
  level,
  enableAscension
}) => {
  let multiplier = 1;
  if (level === 20) {
    multiplier = 1.95;
  }

  if (level === 20 && enableAscension === true) {
    multiplier = 2.35;
  }

  if (level === 30) {
    multiplier = 2.85;
  }

  if (level === 30 && enableAscension === true) {
    multiplier = 3.25;
  }

  if (level === 40) {
    multiplier = 3.75;
  }

  if (level === 40 && enableAscension === true) {
    multiplier = 4.15;
  }

  if (level === 50) {
    multiplier = 4.65;
  }

  if (level === 50 && enableAscension === true) {
    multiplier = 5.05;
  }

  if (level === 60) {
    multiplier = 5.55;
  }

  if (level === 60 && enableAscension === true) {
    multiplier = 5.95;
  }

  if (level === 70) {
    multiplier = 6.45;
  }

  if (level === 70 && enableAscension === true) {
    multiplier = 6.85;
  }

  if (level === 80) {
    multiplier = 7.35;
  }

  return <>{Math.floor(base * multiplier)}</>;
};
